.delete_icon svg {
    color: #C30E2E;
    stroke: #C30E2E;
    transition: color 0.3s ease, stroke 0.3s ease;
}

.delete_icon:hover svg {
    color: #C30E2E;
    stroke: #C30E2E;
}

.trained_icon svg {
    color: #25B93F;
    stroke: #25B93F;
    transition: color 0.3s ease, stroke 0.3s ease;
}

.trained_icon:hover svg {
    color: #25B93F;
    stroke: #25B93F;
}

.training_icon svg {
    color: #FBBC04;
    stroke: #FBBC04;
    transition: color 0.3s ease, stroke 0.3s ease;
}

.training_icon:hover svg {
    color: #FBBC04;
    stroke: #FBBC04;
}

.search_file_icon svg {
    color: #000000;
    stroke: #000000;
    transition: color 0.3s ease, stroke 0.3s ease;
}


.filter_icon svg {
    color: #0B51C5;
    stroke: #0B51C5;
    transition: color 0.3s ease, stroke 0.3s ease;
    fill: #FFFFFF;
}

.filter_icon_active svg {
    color: #0B51C5;
    stroke: #0B51C5;
    transition: color 0.3s ease, stroke 0.3s ease;
    fill: #0B51C5;
}

.upload_success_icon svg {
    color: #25B93F;
    stroke: #25B93F;
    transition: color 0.3s ease, stroke 0.3s ease;
}

.upload_success_icon:hover svg {
    color: #25B93F;
    stroke: #25B93F;
}

.upload_proccess_icon svg {
    color: #FBBC04;
    stroke: #FBBC04;
    transition: color 0.3s ease, stroke 0.3s ease;
}

.upload_proccess_icon:hover svg {
    color: #FBBC04;
    stroke: #FBBC04;
}


.upload_failed_icon svg {
    color: #C30E2E;
    stroke: #C30E2E;
    transition: color 0.3s ease, stroke 0.3s ease;
}

.upload_failed_icon:hover svg {
    color: #C30E2E;
    stroke: #C30E2E;
}

.files_icon svg {
    color: #000000;
    stroke: #000000;
    transition: color 0.3s ease, stroke 0.3s ease;
}