.menu {
  list-style-type: none;
  padding: 0;
  margin-top: 30px;

}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.menu-item .icon,
.menu-item .label {
  color: #ffffff;
  transition: color 0.3s ease;
}

.menu-item:hover {
  margin: 10px;
  border-radius: 10px;
  background-color: #0B51C5;
}

.menu-item:hover .icon,
.menu-item:hover .label {
  color: #ffffff;
}

.menu-item.active {
  background-color: #007bff;
  border-radius: 10px;
}

.menu-item.active .icon,
.menu-item.active .label {
  color: #ffffff;
}

.menu-item.active:hover {
  background-color: #007bff;
}

.menu-item.active:hover .icon,
.menu-item.active:hover .label {
  color: #ffffff;
}

.subsidebar_icon svg {
  color: white;
  stroke: #FFFFFF;
  transition: color 0.3s ease, stroke 0.3s ease;
}

.subsidebar_icon:hover svg {
  color: #FFFFFF;
  stroke: #FFFFFF;
}

.subsidebar_icon.active svg {
  color: #ffffff;
  stroke: #ffffff;
}

.subsidebar_icon.active:hover svg {
  color: #ffffff;
  stroke: #ffffff;
}